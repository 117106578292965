@font-face {
  font-family: vektaGroupL;
  src: url("custom_font/contl-webfont.woff");
}

@font-face {
  font-family: vektaGroupM;
  src: url("custom_font/contm-webfont.woff");
}

@font-face {
  font-family: vektaGroupB;
  src: url("custom_font/contb-webfont.woff");
}

@import "variables";
@import "mixin";

@import "loginPane";
@import "sidebarLeftButtons";
@import "sections";
@import "modals";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat" !important;
  overflow: hidden !important;

  /* width */
  ::-webkit-scrollbar {
    // width: 10px;
    scrollbar-width: thin;
    margin: 8px !important;
  }

  // scrollbar-color: $vektaBlue transparent;
  // scrollbar-gutter: stable;

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $vektaBlue;
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $backgroundGrey;
  }

  .ReactModal__Overlay {
    z-index: 1000;
    background-color: rgb(181 181 181 / 75%) !important;
  }
}

.section {
  background-image: url("../components/construction/results/reportRenderer/cable_carousel_grey_small2.png");
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 1070px;
  background-position-x: 65px;
}

h2 {
  font-weight: 500;
  margin: 8px 0;
}

.container-fluid {
  padding: 0;
}

.ag-center-cols-viewport {
  min-height: unset !important;
}

.new_popup,
.existing_popup {
  input,
  select {
    //border-radius: 8px;
    height: 30px;
    width: 100%;
    margin-bottom: 10px;

    outline: none;
    border: none;

    color: rgb(0, 0, 0);
  }
}

.airdatepickers-container {
  .airdatepicker {
    max-width: 230px;
  }
}

.input-group {
  display: flex;
}

.number-input {
  width: 55%;

  input {
    width: 100%;
    height: 100%;
    padding: 8px;
  }
}

.shiny-progress-container {
  top: 55%;

  .progress {
    left: 25%;
    width: 50vw;
    height: 10px;
  }

  .progress-text {
    left: 25%;
    top: -30px;

    display: flex;
    align-items: center;
    width: 300px;

    background: none;

    color: white;
  }
}

.overlay,
.gis_in_overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  background: #999;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  z-index: 1001;
}

#editTables {
  z-index: 2000 !important;
}

.saveLoadShareBanner {
  width: 100%;

  opacity: 0;
  position: absolute;
  z-index: 2001;

  text-align: center;
  background-color: lightgreen;
  color: white;
}

.toggle_saveLoad {
  animation-name: inout;
  animation-duration: 4s;
}

@keyframes inout {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

a.polyline-measure-controlOnBgColor,
a.polyline-measure-controlOnBgColor:hover {
  background-color: #8f8;
}

.polyline-measure-unicode-icon {
  font-size: 24px;
  font-weight: bold;
}

.polyline-measure-tooltip {
  font: 12px Arial, Helvetica, sans-serif;
  line-height: 12px;
  background-color: $vektaBlue;
  color: white;
  border-radius: 3px;
  box-shadow: 1px 1px 4px #888;
  margin: 0;
  padding: 2px;
  width: auto !important;
  height: auto !important;
  white-space: nowrap;
  text-align: right;
}

.polyline-measure-tooltip-total {
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.polyline-measure-tooltip-difference {
  color: white;
  font-size: 12px;
  font-style: italic;
}

.measureTool {
  svg {
    fill: rgba(0, 0, 0, 0.54);
    border-radius: 50%;

    padding: 5px;
    height: 100%;
  }
}

.leaflet-control-container {
  .leaflet-bar {
    width: 48px;

    a {
      width: 44px !important;

      display: flex;
      align-items: center;
      justify-content: center;
      border: none;

      &#unitControlId {
        font-size: 12px;
        border-top: 3px solid #c3c3c3;

        &:hover {
          background-color: #f5f5f5;
        }
      }

      &:hover {
        background-color: white;

        svg {
          background-color: #dfdfdf;
        }
      }
    }

    .polyline-measure-controlOnBgColor {
      svg {
        background-color: $vektaBlue;
      }

      &:hover {
        svg {
          fill: white;
          background-color: $vektaBlueDark;
        }
      }
    }
  }

  .leafet_legendContaier {
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 8px;

    h5 {
      text-align: center;
    }

    .powerlineosm,
    .powerplantonshoreosm,
    .powerplantpolygonosm {
      width: 140px;
    }

    .legend_close {
      position: fixed;
      bottom: 25px;
      right: 20px;

      width: 35px;

      float: right;
      border: none;
      font-size: medium;
      padding: 5px;

      border-radius: 8px;
      background-color: rgb(209, 209, 209);

      &:hover {
        color: white;
        background-color: lightcoral;
      }
    }

    .leafet_legends {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;

      img {
        max-width: 100%;
      }
    }

    .legend_minimise {
      width: 100%;
      height: 100%;
      border: none;
      background: none;

      span {
        font-size: medium;
      }
    }
  }
}

.legendContainer,
.coordMarker {
  width: 48px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;

  // background: #fff;
  // border-radius: 5px;
  // border: 2px solid rgba(0, 0, 0, 0.2);
  // background-clip: padding-box;

  button {
    appearance: none;
    background: none;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 20px;
  }

  .activeLegends {
    display: none;
  }

  .activeLegends_close {
    display: none;
  }

  &:hover {
    //background-color: #f4f4f4;
    cursor: pointer;
  }
}

.legendContainer_toggle {
  width: 245px;
  height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;

  position: relative;

  button svg {
    display: none;
  }

  .activeLegends {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 240px;

    position: absolute;
    left: 0;
    top: 0;

    .activeLegend_container {
      display: flex;
      flex-direction: column;

      padding: 10px;
      background-color: white;

      width: 100%;

      img {
        width: fit-content;
      }

      .Power_Line_,
      .Power_Plant_Polygon_,
      .Power_Plant_Onshore_ {
        width: 140px;
      }
    }
  }

  .activeLegends_close {
    display: block;
    position: absolute;
    right: 0;
    top: 5px;

    font-size: 15px;
    border: 2px solid $vektaBlue;
    border-radius: 8px;

    &:hover {
      background-color: #f4f4f4;
      cursor: pointer;
    }
  }
}

.leaflet-container {
  .siteBuilder_options {
    .input {
      width: 100%;

      input,
      select {
        height: 45px !important;
        border-radius: 8px;
        width: 100%;
        border: 2px solid #007592;
      }

      .toggle_missingInfoShake {
        animation: shakeError 0.2s ease-in-out 0s 2;
      }

      @keyframes shakeError {
        0% {
          margin-left: 0rem;
        }

        25% {
          margin-left: 0.5rem;
        }

        75% {
          margin-left: -0.5rem;
        }

        100% {
          margin-left: 0rem;
        }
      }

      .toggle_missingInfo {
        border: 2px solid red;
      }
    }
  }

  .siteBuilder_infoPopup {
    font-size: 1.2rem;

    hr {
      margin-top: 0;
    }

    h4 {
      margin-right: 10px;
      color: $vektaBlue;
    }
  }
}

.coordMarker_toggle {
  background-color: $vektaBlue !important;

  button {
    fill: white;
  }
}

//   .leaflet-right {
//     .leaflet-control-layers {
//       a {
//         //background-image: url("../Media/globe-solid.svg");
//         /*set you value*/
//         //background-size: calc(25px / var(--scaleFactor));
//         background-size: 25px;

//         width: 36px !important;
//         height: 36px !important;
//       }
//     }
//   }
