.cableModal {
    .cableRow .cableSelect {
        width: 55%;

        input {
            height: 2.1rem;
        }
    }

    footer {
        display: flex;
        justify-content: flex-end;

        button {
            margin-left: 8px;
        }
    }
}

.runSettingsModal {
    .settingsRow .settingsSelect {
        width: 55%;

        input {
            height: 2.1rem;
        }
    }

    footer {
        display: flex;
        justify-content: flex-end;

        button {
            margin-left: 8px;
        }
    }
}

.filterModal {
    .cableRow .filterSelect {
        width: 55%;

        input {
            height: 2.1rem;
        }
    }

    footer {
        display: flex;
        justify-content: space-between;

        button {
            margin-left: 8px;
        }
    }
}

.pdfForm {

    .cableRow input,
    .cableRow button {
        width: 55%;
        height: 3rem;
    }

    .cableRow .custom-file-upload {
        border: 2px solid #009ec6;
        border-radius: 8px;
        background: white;

        padding: 8px;

        color: #009ec6;
        font-size: 1rem;

        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        width: 55%;
        height: 3rem;
    }

    .cableRow input[type=file] {
        display: none;
    }

    footer {
        display: flex;
        justify-content: flex-end;

        button {
            margin-left: 8px;
        }
    }
}

.commentSheet {
    footer {
        display: flex;
        justify-content: space-between;

        button {
            margin-left: 8px;
        }
    }
}