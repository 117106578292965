.sidebarButtons {
  width: 70px;
  height: 100vh;

  background-color: $vektaBlue;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .widgetBtns {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      //margin: calc(10px / var(--scaleFactor)) 0;
      margin: 10px 0;
      @include widgetButtons;
      //padding: calc(5px / var(--scaleFactor));
      padding: 5px;
      fill: white;

      //height: calc(50px / var(--scaleFactor));
      //width: calc(50px / var(--scaleFactor));
      height: 50px;
      width: 50px;
      // border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      svg,
      img,
      i {
        //width: calc(35px / var(--scaleFactor));
        //height: calc(35px / var(--scaleFactor));

        //font-size: calc(30px / var(--scaleFactor));

        width: 35px;
        height: 35px;
        font-size: 30px;
      }

      &.active {
        border-left: 2px solid white;
      }
    }

    .vektaGroup {
      width: 100%;

      button {
        width: 100%;
        margin: 0;
        border-radius: 0;
        //height: calc(55px / var(--scaleFactor));
        height: 55px;

        img {
          //width: calc(50px / var(--scaleFactor));
          //height: calc(38px / var(--scaleFactor));
          filter: invert(0);

          width: 50px;
          height: 38px;
        }
      }
    }
  }
}
