@mixin closeBtn {
  appearance: none;
  background: none;
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);

  text-transform: uppercase;

  font-family: vektaGroupM;
  font-size: inherit;
  color: $vektaBlue;

  display: inline-block;
  padding: 10px 15px;

  cursor: pointer;

  &:hover {
    box-shadow: inset 5px 5px rgb(201, 201, 201);
  }
}

@mixin minimizeOptions {
  border: none;
  outline: none;
  background: none;
  color: white;
  font-size: xx-large;
  cursor: pointer;
  transition: 0.7s ease-in-out;

  &:hover {
    transform: rotate(0.13turn);
  }
}

@mixin sidebarWidget {
  display: block;

  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;

  width: 88%;

  background-color: rgba(29, 19, 46, 0);

  transition: 0.5s ease-in-out;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      @include minimizeOptions;
    }
  }
}

@mixin widgetButtons {
  background: none;
  border: none;
  color: white;

  &:hover {
    background-color: $vektaBlueTrans;
  }
}

@mixin toggleSwitch {
  display: flex;
  align-items: center;

  .choice {
    text-transform: uppercase;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 10px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &::before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: $vektaOtherBlue;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  input:checked + .slider {
    background-color: white;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px white;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
