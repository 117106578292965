.section_collection,
.section_analysis,
.section_results {
  // header {
  //   button {
  //     width: 10rem;
  //     padding: 5px;
  //     height: 2.5rem;

  //     background-color: $vektaBlueDark;
  //     color: white;

  //     border: none;
  //     border-radius: 8px;

  //     font-size: 16px;
  //     font-weight: 600;

  //     &:hover {
  //       background-color: white;
  //       color: $vektaBlue;
  //       box-shadow: 0px 0px 8px 0px #939393;
  //     }
  //   }
  // }

  footer {
    .actionButton {
      width: 10rem;
      padding: 5px;
      height: 3rem;

      background-color: $vektaBlueDark;
      color: white;

      border: none;
      border-radius: 8px;

      font-size: 16px;
      font-weight: 600;

      &:hover {
        background-color: white;
        color: $vektaBlue;
        box-shadow: 0px 0px 8px 0px #939393;
      }
    }

    .actionButton {
      position: absolute;
      bottom: 16px;
      right: 16px;
    }

    .settingsButton {
      position: absolute;
      bottom: 16px;
      left: 16px;
    }
  }
}