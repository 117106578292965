$vektaBlue : #00a0c6;
$vektaBlueDark : #007592;
$vektaOtherBlue: #9feed6;
$blueDark: #005f77;
$vektaBlueLight : #90cedd;
$vektaBlueTrans: #009ec641;
$environmentGreen : rgb(97, 162, 41);
$green : #00c454;
$greenTrans : rgba(0, 196, 85, 0.411);
$notWhite: #f3f3f3;
$scrollbarColor: #f2f2f2;
$scrollbarColorHover: rgba(255, 255, 255, 0.5);
$backgroundGrey: #d4d4d454;