@media only screen and (min-width: 481px) {
  .GIS_Loader {
    .toolLogin_form {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .firebase_oAuth {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button img{
          filter: invert(1);
        }
      }

      .toolLogin_firebase {
        display: flex;
        flex-direction: column;

        .p-inputtext:enabled:focus {
          box-shadow: none;
        }

        .p-float-label input:focus~label,
        .p-float-label .p-inputwrapper-filled~label,
        .p-float-label .p-inputwrapper-focus~label {
          font-size: 16px;
        }

        .p-password {
          i svg {
            width: 16px;
            height: 16px;
          }

          input {
            width: 100%;
          }
        }

        .passwordInput {
          margin-top: 11px;

          a {
            font-size: 12px;
            float: right;
          }
        }
      }

      .forgottenPassword_overlay {
        background: #8080807d;
        height: 100%;
        width: 32%;
        z-index: 1;
        position: fixed;
        top: 0;
        left: 0;
      }


      .toolLogin_footer {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-top: 32px;
      }

      .forgottenPassword {
        text-align: right;
        text-decoration: none;
        font-size: 12px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .forgottenPassword_popup {
        position: fixed;
        inset: 44% 5.5%;
        background-color: white;
        width: fit-content;
        height: fit-content;
        padding: 16px;
        border-radius: 8px;
        z-index: 2;

        .input label,
        .input input {
          color: black;
        }

        .popup_footer {
          display: flex;
          align-items: center;
          justify-content: center;

          button {
            background-color: $vektaBlueDark;
            color: white;

            border: none;
            border-radius: 8px;

            font-size: 16px;
            font-weight: 600;

            padding: 8px;
            margin: 8px;

            &:hover {
              background-color: $vektaBlueDark;
              box-shadow: 0px 0px 8px 0px #939393;
            }
          }
        }
      }

      .termsConditions {
        font-size: 12px;
        margin-top: 16px;

        a {
          color: white;
        }
      }
    }

    .emailSent_notification {
      position: absolute;

      opacity: 0;
      width: 91%;
      padding: 8px;
      font-size: 16px;
      font-weight: 600;
      border-radius: 8px;
      margin: 8px 0;


      text-align: center;
      background: rgba(0, 196, 84, 1);
      color: white;

      &.toggle_display {
        animation-name: inout;
        animation-duration: 4s;
      }

      @keyframes inout {
        0% {
          opacity: 0;
        }

        25% {
          opacity: 1;
        }

        50% {
          opacity: 1;
        }

        75% {
          opacity: 1;
        }

        100% {
          opacity: 0;
        }
      }
    }

    .toolSignup_inputs {
      .p-inputtext:enabled:focus {
        box-shadow: none;
      }
  
      .p-float-label input:focus~label,
      .p-float-label .p-inputwrapper-filled~label,
      .p-float-label .p-inputwrapper-focus~label {
        font-size: 16px;
      }
  
      .p-password {
        i svg {
          width: 16px;
          height: 16px;
        }
  
        input {
          width: 100%;
        }
      }
  
      .toolSignup_error {
        position: relative;
        opacity: 0;
  
        span {
          width: 100%;
  
          text-align: center;
          background-color: rgb(255, 87, 87);
          color: white;
        }
      }
  
      .toggle_error {
        animation-name: inout;
        animation-duration: 4s;
      }
  
      @keyframes inout {
        0% {
          opacity: 0;
        }
  
        25% {
          opacity: 1;
        }
  
        50% {
          opacity: 1;
        }
  
        75% {
          opacity: 1;
        }
  
        100% {
          opacity: 0;
        }
      }
    }

    .toolSignup_footer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      margin-top: 24px;

      .termsConditions {
        font-size: 12px;
        margin-top: 16px;

        a {
          color: white;
        }
      }

      .p-float-label input.p-filled~label {
        top: -1rem !important;
      }

      .p-inputtext:enabled:focus {
        border: none;
        outline: none;
        box-shadow: none;
      }

      .p-float-label input:focus~label,
      .p-float-label .p-inputwrapper-filled~label,
      .p-float-label .p-inputwrapper-focus~label {
        font-size: 16px;
        top: -1.5rem;
      }

      .p-password {
        i svg {
          width: 16px;
          height: 16px;
        }

        input {
          width: 100%;
        }
      }
    }
  }
}