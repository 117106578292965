@font-face {
  font-family: vektaGroupL;
  src: url("custom_font/contl-webfont.woff");
}
@font-face {
  font-family: vektaGroupM;
  src: url("custom_font/contm-webfont.woff");
}
@font-face {
  font-family: vektaGroupB;
  src: url("custom_font/contb-webfont.woff");
}
@media only screen and (min-width: 481px) {
  .GIS_Loader .toolLogin_form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .GIS_Loader .toolLogin_form .firebase_oAuth {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .GIS_Loader .toolLogin_form .firebase_oAuth button img {
    filter: invert(1);
  }
  .GIS_Loader .toolLogin_form .toolLogin_firebase {
    display: flex;
    flex-direction: column;
  }
  .GIS_Loader .toolLogin_form .toolLogin_firebase .p-inputtext:enabled:focus {
    box-shadow: none;
  }
  .GIS_Loader .toolLogin_form .toolLogin_firebase .p-float-label input:focus ~ label,
  .GIS_Loader .toolLogin_form .toolLogin_firebase .p-float-label .p-inputwrapper-filled ~ label,
  .GIS_Loader .toolLogin_form .toolLogin_firebase .p-float-label .p-inputwrapper-focus ~ label {
    font-size: 16px;
  }
  .GIS_Loader .toolLogin_form .toolLogin_firebase .p-password i svg {
    width: 16px;
    height: 16px;
  }
  .GIS_Loader .toolLogin_form .toolLogin_firebase .p-password input {
    width: 100%;
  }
  .GIS_Loader .toolLogin_form .toolLogin_firebase .passwordInput {
    margin-top: 11px;
  }
  .GIS_Loader .toolLogin_form .toolLogin_firebase .passwordInput a {
    font-size: 12px;
    float: right;
  }
  .GIS_Loader .toolLogin_form .forgottenPassword_overlay {
    background: rgba(128, 128, 128, 0.4901960784);
    height: 100%;
    width: 32%;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
  }
  .GIS_Loader .toolLogin_form .toolLogin_footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
  }
  .GIS_Loader .toolLogin_form .forgottenPassword {
    text-align: right;
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
  }
  .GIS_Loader .toolLogin_form .forgottenPassword:hover {
    text-decoration: underline;
  }
  .GIS_Loader .toolLogin_form .forgottenPassword_popup {
    position: fixed;
    inset: 44% 5.5%;
    background-color: white;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 16px;
    border-radius: 8px;
    z-index: 2;
  }
  .GIS_Loader .toolLogin_form .forgottenPassword_popup .input label,
  .GIS_Loader .toolLogin_form .forgottenPassword_popup .input input {
    color: black;
  }
  .GIS_Loader .toolLogin_form .forgottenPassword_popup .popup_footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .GIS_Loader .toolLogin_form .forgottenPassword_popup .popup_footer button {
    background-color: #007592;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    padding: 8px;
    margin: 8px;
  }
  .GIS_Loader .toolLogin_form .forgottenPassword_popup .popup_footer button:hover {
    background-color: #007592;
    box-shadow: 0px 0px 8px 0px #939393;
  }
  .GIS_Loader .toolLogin_form .termsConditions {
    font-size: 12px;
    margin-top: 16px;
  }
  .GIS_Loader .toolLogin_form .termsConditions a {
    color: white;
  }
  .GIS_Loader .emailSent_notification {
    position: absolute;
    opacity: 0;
    width: 91%;
    padding: 8px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    margin: 8px 0;
    text-align: center;
    background: rgb(0, 196, 84);
    color: white;
  }
  .GIS_Loader .emailSent_notification.toggle_display {
    animation-name: inout;
    animation-duration: 4s;
  }
  @keyframes inout {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .GIS_Loader .toolSignup_inputs .p-inputtext:enabled:focus {
    box-shadow: none;
  }
  .GIS_Loader .toolSignup_inputs .p-float-label input:focus ~ label,
  .GIS_Loader .toolSignup_inputs .p-float-label .p-inputwrapper-filled ~ label,
  .GIS_Loader .toolSignup_inputs .p-float-label .p-inputwrapper-focus ~ label {
    font-size: 16px;
  }
  .GIS_Loader .toolSignup_inputs .p-password i svg {
    width: 16px;
    height: 16px;
  }
  .GIS_Loader .toolSignup_inputs .p-password input {
    width: 100%;
  }
  .GIS_Loader .toolSignup_inputs .toolSignup_error {
    position: relative;
    opacity: 0;
  }
  .GIS_Loader .toolSignup_inputs .toolSignup_error span {
    width: 100%;
    text-align: center;
    background-color: rgb(255, 87, 87);
    color: white;
  }
  .GIS_Loader .toolSignup_inputs .toggle_error {
    animation-name: inout;
    animation-duration: 4s;
  }
  @keyframes inout {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .GIS_Loader .toolSignup_footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 24px;
  }
  .GIS_Loader .toolSignup_footer .termsConditions {
    font-size: 12px;
    margin-top: 16px;
  }
  .GIS_Loader .toolSignup_footer .termsConditions a {
    color: white;
  }
  .GIS_Loader .toolSignup_footer .p-float-label input.p-filled ~ label {
    top: -1rem !important;
  }
  .GIS_Loader .toolSignup_footer .p-inputtext:enabled:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
  .GIS_Loader .toolSignup_footer .p-float-label input:focus ~ label,
  .GIS_Loader .toolSignup_footer .p-float-label .p-inputwrapper-filled ~ label,
  .GIS_Loader .toolSignup_footer .p-float-label .p-inputwrapper-focus ~ label {
    font-size: 16px;
    top: -1.5rem;
  }
  .GIS_Loader .toolSignup_footer .p-password i svg {
    width: 16px;
    height: 16px;
  }
  .GIS_Loader .toolSignup_footer .p-password input {
    width: 100%;
  }
}
.sidebarButtons {
  width: 70px;
  height: 100vh;
  background-color: #00a0c6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebarButtons .widgetBtns {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sidebarButtons .widgetBtns button {
  margin: 10px 0;
  background: none;
  border: none;
  color: white;
  padding: 5px;
  fill: white;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebarButtons .widgetBtns button:hover {
  background-color: rgba(0, 158, 198, 0.2549019608);
}
.sidebarButtons .widgetBtns button svg,
.sidebarButtons .widgetBtns button img,
.sidebarButtons .widgetBtns button i {
  width: 35px;
  height: 35px;
  font-size: 30px;
}
.sidebarButtons .widgetBtns button.active {
  border-left: 2px solid white;
}
.sidebarButtons .widgetBtns .vektaGroup {
  width: 100%;
}
.sidebarButtons .widgetBtns .vektaGroup button {
  width: 100%;
  margin: 0;
  border-radius: 0;
  height: 55px;
}
.sidebarButtons .widgetBtns .vektaGroup button img {
  filter: invert(0);
  width: 50px;
  height: 38px;
}

.section_collection footer .actionButton,
.section_analysis footer .actionButton,
.section_results footer .actionButton {
  width: 10rem;
  padding: 5px;
  height: 3rem;
  background-color: #007592;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
}
.section_collection footer .actionButton:hover,
.section_analysis footer .actionButton:hover,
.section_results footer .actionButton:hover {
  background-color: white;
  color: #00a0c6;
  box-shadow: 0px 0px 8px 0px #939393;
}
.section_collection footer .actionButton,
.section_analysis footer .actionButton,
.section_results footer .actionButton {
  position: absolute;
  bottom: 16px;
  right: 16px;
}
.section_collection footer .settingsButton,
.section_analysis footer .settingsButton,
.section_results footer .settingsButton {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.cableModal .cableRow .cableSelect {
  width: 55%;
}
.cableModal .cableRow .cableSelect input {
  height: 2.1rem;
}
.cableModal footer {
  display: flex;
  justify-content: flex-end;
}
.cableModal footer button {
  margin-left: 8px;
}

.runSettingsModal .settingsRow .settingsSelect {
  width: 55%;
}
.runSettingsModal .settingsRow .settingsSelect input {
  height: 2.1rem;
}
.runSettingsModal footer {
  display: flex;
  justify-content: flex-end;
}
.runSettingsModal footer button {
  margin-left: 8px;
}

.filterModal .cableRow .filterSelect {
  width: 55%;
}
.filterModal .cableRow .filterSelect input {
  height: 2.1rem;
}
.filterModal footer {
  display: flex;
  justify-content: space-between;
}
.filterModal footer button {
  margin-left: 8px;
}

.pdfForm .cableRow input,
.pdfForm .cableRow button {
  width: 55%;
  height: 3rem;
}
.pdfForm .cableRow .custom-file-upload {
  border: 2px solid #009ec6;
  border-radius: 8px;
  background: white;
  padding: 8px;
  color: #009ec6;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 55%;
  height: 3rem;
}
.pdfForm .cableRow input[type=file] {
  display: none;
}
.pdfForm footer {
  display: flex;
  justify-content: flex-end;
}
.pdfForm footer button {
  margin-left: 8px;
}

.commentSheet footer {
  display: flex;
  justify-content: space-between;
}
.commentSheet footer button {
  margin-left: 8px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat" !important;
  overflow: hidden !important;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
body ::-webkit-scrollbar {
  scrollbar-width: thin;
  margin: 8px !important;
}
body ::-webkit-scrollbar-track {
  border-radius: 8px;
}
body ::-webkit-scrollbar-thumb {
  background: #00a0c6;
  border-radius: 8px;
}
body ::-webkit-scrollbar-thumb:hover {
  background: rgba(212, 212, 212, 0.3294117647);
}
body .ReactModal__Overlay {
  z-index: 1000;
  background-color: rgba(181, 181, 181, 0.75) !important;
}

.section {
  background-image: url("../components/construction/results/reportRenderer/cable_carousel_grey_small2.png");
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 1070px;
  background-position-x: 65px;
}

h2 {
  font-weight: 500;
  margin: 8px 0;
}

.container-fluid {
  padding: 0;
}

.ag-center-cols-viewport {
  min-height: unset !important;
}

.new_popup input,
.new_popup select,
.existing_popup input,
.existing_popup select {
  height: 30px;
  width: 100%;
  margin-bottom: 10px;
  outline: none;
  border: none;
  color: rgb(0, 0, 0);
}

.airdatepickers-container .airdatepicker {
  max-width: 230px;
}

.input-group {
  display: flex;
}

.number-input {
  width: 55%;
}
.number-input input {
  width: 100%;
  height: 100%;
  padding: 8px;
}

.shiny-progress-container {
  top: 55%;
}
.shiny-progress-container .progress {
  left: 25%;
  width: 50vw;
  height: 10px;
}
.shiny-progress-container .progress-text {
  left: 25%;
  top: -30px;
  display: flex;
  align-items: center;
  width: 300px;
  background: none;
  color: white;
}

.overlay,
.gis_in_overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  background: #999;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  z-index: 1001;
}

#editTables {
  z-index: 2000 !important;
}

.saveLoadShareBanner {
  width: 100%;
  opacity: 0;
  position: absolute;
  z-index: 2001;
  text-align: center;
  background-color: lightgreen;
  color: white;
}

.toggle_saveLoad {
  animation-name: inout;
  animation-duration: 4s;
}

@keyframes inout {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
a.polyline-measure-controlOnBgColor,
a.polyline-measure-controlOnBgColor:hover {
  background-color: #8f8;
}

.polyline-measure-unicode-icon {
  font-size: 24px;
  font-weight: bold;
}

.polyline-measure-tooltip {
  font: 12px Arial, Helvetica, sans-serif;
  line-height: 12px;
  background-color: #00a0c6;
  color: white;
  border-radius: 3px;
  box-shadow: 1px 1px 4px #888;
  margin: 0;
  padding: 2px;
  width: auto !important;
  height: auto !important;
  white-space: nowrap;
  text-align: right;
}

.polyline-measure-tooltip-total {
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.polyline-measure-tooltip-difference {
  color: white;
  font-size: 12px;
  font-style: italic;
}

.measureTool svg {
  fill: rgba(0, 0, 0, 0.54);
  border-radius: 50%;
  padding: 5px;
  height: 100%;
}

.leaflet-control-container .leaflet-bar {
  width: 48px;
}
.leaflet-control-container .leaflet-bar a {
  width: 44px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.leaflet-control-container .leaflet-bar a#unitControlId {
  font-size: 12px;
  border-top: 3px solid #c3c3c3;
}
.leaflet-control-container .leaflet-bar a#unitControlId:hover {
  background-color: #f5f5f5;
}
.leaflet-control-container .leaflet-bar a:hover {
  background-color: white;
}
.leaflet-control-container .leaflet-bar a:hover svg {
  background-color: #dfdfdf;
}
.leaflet-control-container .leaflet-bar .polyline-measure-controlOnBgColor svg {
  background-color: #00a0c6;
}
.leaflet-control-container .leaflet-bar .polyline-measure-controlOnBgColor:hover svg {
  fill: white;
  background-color: #007592;
}
.leaflet-control-container .leafet_legendContaier {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 8px;
}
.leaflet-control-container .leafet_legendContaier h5 {
  text-align: center;
}
.leaflet-control-container .leafet_legendContaier .powerlineosm,
.leaflet-control-container .leafet_legendContaier .powerplantonshoreosm,
.leaflet-control-container .leafet_legendContaier .powerplantpolygonosm {
  width: 140px;
}
.leaflet-control-container .leafet_legendContaier .legend_close {
  position: fixed;
  bottom: 25px;
  right: 20px;
  width: 35px;
  float: right;
  border: none;
  font-size: medium;
  padding: 5px;
  border-radius: 8px;
  background-color: rgb(209, 209, 209);
}
.leaflet-control-container .leafet_legendContaier .legend_close:hover {
  color: white;
  background-color: lightcoral;
}
.leaflet-control-container .leafet_legendContaier .leafet_legends {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.leaflet-control-container .leafet_legendContaier .leafet_legends img {
  max-width: 100%;
}
.leaflet-control-container .leafet_legendContaier .legend_minimise {
  width: 100%;
  height: 100%;
  border: none;
  background: none;
}
.leaflet-control-container .leafet_legendContaier .legend_minimise span {
  font-size: medium;
}

.legendContainer,
.coordMarker {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.legendContainer button,
.coordMarker button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.legendContainer svg,
.coordMarker svg {
  width: 20px;
}
.legendContainer .activeLegends,
.coordMarker .activeLegends {
  display: none;
}
.legendContainer .activeLegends_close,
.coordMarker .activeLegends_close {
  display: none;
}
.legendContainer:hover,
.coordMarker:hover {
  cursor: pointer;
}

.legendContainer_toggle {
  width: 245px;
  height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}
.legendContainer_toggle button svg {
  display: none;
}
.legendContainer_toggle .activeLegends {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 240px;
  position: absolute;
  left: 0;
  top: 0;
}
.legendContainer_toggle .activeLegends .activeLegend_container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  width: 100%;
}
.legendContainer_toggle .activeLegends .activeLegend_container img {
  width: -moz-fit-content;
  width: fit-content;
}
.legendContainer_toggle .activeLegends .activeLegend_container .Power_Line_,
.legendContainer_toggle .activeLegends .activeLegend_container .Power_Plant_Polygon_,
.legendContainer_toggle .activeLegends .activeLegend_container .Power_Plant_Onshore_ {
  width: 140px;
}
.legendContainer_toggle .activeLegends_close {
  display: block;
  position: absolute;
  right: 0;
  top: 5px;
  font-size: 15px;
  border: 2px solid #00a0c6;
  border-radius: 8px;
}
.legendContainer_toggle .activeLegends_close:hover {
  background-color: #f4f4f4;
  cursor: pointer;
}

.leaflet-container .siteBuilder_options .input {
  width: 100%;
}
.leaflet-container .siteBuilder_options .input input,
.leaflet-container .siteBuilder_options .input select {
  height: 45px !important;
  border-radius: 8px;
  width: 100%;
  border: 2px solid #007592;
}
.leaflet-container .siteBuilder_options .input .toggle_missingInfoShake {
  animation: shakeError 0.2s ease-in-out 0s 2;
}
@keyframes shakeError {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}
.leaflet-container .siteBuilder_options .input .toggle_missingInfo {
  border: 2px solid red;
}
.leaflet-container .siteBuilder_infoPopup {
  font-size: 1.2rem;
}
.leaflet-container .siteBuilder_infoPopup hr {
  margin-top: 0;
}
.leaflet-container .siteBuilder_infoPopup h4 {
  margin-right: 10px;
  color: #00a0c6;
}

.coordMarker_toggle {
  background-color: #00a0c6 !important;
}
.coordMarker_toggle button {
  fill: white;
}/*# sourceMappingURL=index.css.map */